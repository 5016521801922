import React, { useState, useEffect } from "react";
import {navigate} from 'gatsby';

import EventType from "./form/event-type";
import FoodService from "./form/food-service";
import Assistants from "./form/assistants";
import DateAndLocation from "./form/date-location";
import Contact from "./form/contact";
import LoadingStep from "./form/loading";
import axios from "axios";

let formData = {};

const Form = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [animatingOut, setAnimatingOut] = useState(false);
    const [animatingIn, setAnimatingIn] = useState(false);
    const [submiting, setSubmiting] = useState(false);

    useEffect(() => {
        setActiveStep(1);
        setTimeout(() => {
            setAnimatingIn(1);
        }, 10);
    }, []);

    const next = (data = {}) => {
        // Get Data
        formData = { ...formData, ...data };

        document
            .getElementsByTagName("body")[0]
            .scrollIntoView({ behavior: "smooth" });

        // Animate out
        const nextStep = activeStep + 1;
        setAnimatingOut(activeStep);
        setTimeout(() => {
            // Animate IN
            setActiveStep(nextStep);
            setAnimatingOut(false);
            setTimeout(() => {
                setAnimatingIn(nextStep);
            }, 10);
        }, 200);
    };

    const submit = data => {
        formData = { ...formData, ...data };

        formData.meta = window.location.search;
        
        setSubmiting(true);
        
        axios.post('https://cateringservicerio.com/send.php', formData).then(res => {
            if (typeof window !== "undefined") {
                if (window.fbq != null) {
                    window.fbq('track', 'Lead');
                }
            }
            setTimeout(() => {
                navigate('/thank-you');
            }, 1000);
        });
    };

    const classNamesForSteps = [1, 2, 3, 4, 5, 6, 7].map(item => {
        const classNames = ["col"];
        if (activeStep === item) {
            classNames.push("active");
        }
        if (animatingOut === item) {
            classNames.push("out");
        } else if (animatingIn === item) {
            classNames.push("in");
        }
        return classNames;
    });

    return (
        <form id="mainForm" className="steps">
            <EventType
                className={classNamesForSteps[0].join(" ")}
                index="1"
                onClick={next}
            />
            <FoodService
                className={classNamesForSteps[1].join(" ")}
                index="2"
                onClick={next}
            />
            <Assistants
                className={classNamesForSteps[2].join(" ")}
                index="3"
                onClick={next}
            />
            <DateAndLocation
                className={classNamesForSteps[3].join(" ")}
                index="4"
                onClick={next}
            />
            <LoadingStep
                onScreen={activeStep === 5}
                className={classNamesForSteps[4].join(" ")}
                index="5"
                onClick={next}
            />
            <Contact
                className={classNamesForSteps[5].join(" ")}
                index="6"
                onClick={submit}
                submiting={submiting}
            />
        </form>
    );
};

export default Form;
