import React, { useEffect, useState } from "react";

let activeLabel = 0;

const TextRotator = ({ labels, time }) => {
    const [label, setLabel] = useState(labels[activeLabel]);
    const [className, setClassName] = useState("");

    useEffect(() => {
        const interv = setInterval(() => {
            activeLabel++;
            if (activeLabel >= labels.length) {
                clearInterval(interv);
            } else {
                setClassName("change");
                setTimeout(() => {
                    setClassName("");
                    setLabel(labels[activeLabel]);
                }, 100);
            }
        }, time / labels.length);
    }, []);
    return <h4 className={`text-rotator ${className}`}>{label}</h4>;
};

export default TextRotator;
