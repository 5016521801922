import React, { useState } from "react";

import Step from "./step";
import Options from "../common/options";

import * as yup from "yup";

const FoodService = ({ onClick, ...props }) => {
    const [food_services, setFoodServices] = useState("");
    const [isValid, setIsValid] = useState(true);

    const validate = () => {
        let schema = yup.string().required();
        return schema
            .validate(food_services)
            .then(success => {
                if (!!success)
                    onClick({
                        services: food_services,
                    });
            })
            .catch(errors => {
                setIsValid(false);
            });
    };

    const food_service_options = [
        "Parrillada",
        "Chicharronada",
        "Desayuno",
        "Almuerzo",
        "Cena",
        "Brunch",
        "Coffee Break",
        "Cóctel",
        "Otro",
    ];

    const handleSelect = selected => {
        setFoodServices(selected.join(", "));
        setIsValid(true);
    };

    return (
        <Step
            title="Que tipo de servicio(s) deseas cotizar?"
            description="Puedes seleccionar uno o más"
            {...props}
            onClick={validate}
        >
            <div className="step-content">
                <Options
                    values={food_service_options}
                    multiple={true}
                    onSelect={handleSelect}
                />
                {!isValid && (
                    <p className="step-error">
                        Seleccione los servicios que desea cotizar
                    </p>
                )}
            </div>
        </Step>
    );
};

export default FoodService;
