import React from "react";
import Form from "./form";

const Hero = () => {
    return (
        <section id="hero">
            <div className="container">
                <Form />
            </div>
        </section>
    );
};

export default Hero;
