import React, { useState } from "react";

import Step from "./step";
import Options from "../common/options";

import * as yup from "yup";

const EventType = ({ onClick, ...props }) => {
    const [isValid, setIsValid] = useState(true);
    const [event_type, setEventType] = useState("");

    const validate = () => {
        let schema = yup.string().required();
        return schema
            .validate(event_type)
            .then(success => {
                if (!!success) {
                    setIsValid(true);
                    onClick({
                        event: event_type,
                    });
                }
            })
            .catch(errors => {
                console.log(errors);
                setIsValid(false);
            });
    };

    const event_types = [
        "Fiesta",
        "Matrimonio",
        "Empresarial",
        "15 años",
        "Baby Shower",
        "Otro",
    ];

    const handleSelect = selected => {
        setEventType(selected.length > 0 ? selected[0] : false);
        setIsValid(true);
    };

    return (
        <Step
            title="Que tipo de evento deseas cotizar?"
            description="Elije una opción o selecciona Otro para continuar"
            {...props}
            onClick={validate}
        >
            <div className="step-content">
                <Options values={event_types} onSelect={handleSelect} />
                {!isValid && (
                    <p className="step-error">Seleccione el tipo de evento</p>
                )}
            </div>
        </Step>
    );
};

export default EventType;
