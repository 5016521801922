import React, { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import * as yup from "yup";
import { useFormik } from "formik";

import Step from "./step";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerButton = forwardRef(({ value, className, ...props }, ref) => (
    <button {...props} className={`text-left ${className}`} type="button">
        {value}
    </button>
));

const DateAndLocation = ({ onClick, ...props }) => {
    registerLocale("es", es);

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const formik = useFormik({
        initialValues: {
            date: "",
            no_date: false,
            location: "",
            recommend: false,
        },
        validationSchema: yup.object({
            date: yup.date().when("no_date", {
                is: false,
                then: yup
                    .date()
                    .min(yesterday)
                    .required(),
            }),
            no_date: yup.boolean(),
            location: yup.string().when("recommend", {
                is: false,
                then: yup.string().required(),
            }),
            recommend: yup.boolean(),
        }),
        onSubmit: values => {
            onClick(values);
        },
    });

    const getData = () => {
        return {
            date: !formik.values.no_date ? formik.values.date : "0-0-0",
            location: !formik.values.recommend
                ? formik.values.location
                : "Recomendar",
        };
    };

    let classNames = {
        date: ["form-control"],
        location: ["form-control"],
    };

    if (formik.touched.date) {
        if (formik.errors.date) {
            classNames.date.push("is-invalid");
        } else {
            classNames.date.push("is-valid");
        }
    }
    if (formik.touched.location) {
        if (formik.errors.location) {
            classNames.location.push("is-invalid");
        } else {
            classNames.location.push("is-valid");
        }
    }

    return (
        <Step
            title="Cuándo y Dónde será el evento?"
            description="Puede ser el lugar y la fecha definitivos o tentativos"
            {...props}
            onClick={formik.handleSubmit}
            getData={getData}
        >
            <div className="step-content date-location">
                <div className="row form-group">
                    <div className="col">
                        <label htmlFor="input_date">Fecha:</label>
                        <DatePicker
                            id="input_date"
                            onChange={v => formik.setFieldValue("date", v)}
                            onBlur={formik.handleBlur}
                            selected={formik.values.date}
                            locale="es"
                            className={classNames.date.join(" ")}
                            minDate={new Date()}
                            readOnly={formik.values.no_date}
                            disabled={formik.values.no_date}
                            customInput={<DatePickerButton />}
                            popperPlacement="center"
                            dateFormat="dd-MM-yyyy"
                        />
                        <div className="form-check mt-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="check_no_date"
                                name="no_date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.no_date}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="check_no_date"
                            >
                                Aún no tengo una fecha definida
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col">
                        <label htmlFor="input_location">
                            Dirección exacta o Ubicación de Interés
                        </label>
                        <input
                            type="text"
                            className={classNames.location.join(" ")}
                            id="input_location"
                            name="location"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.location}
                            readOnly={formik.values.recommend}
                            disabled={formik.values.recommend}
                        />
                        <div className="form-check mt-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="check_need_location"
                                name="recommend"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.recommend}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="check_need_location"
                            >
                                Aún no tengo lugar y me gustaría ver
                                recomendaciones
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Step>
    );
};

export default DateAndLocation;
