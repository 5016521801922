import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";

import Step from "./step";

const Contact = ({ onClick, submiting, ...props }) => {
    const formik = useFormik({
        initialValues: {
            name: "",
            phone: "",
            email: "",
            comments: "",
            manteleria: false,
            sillas: false,
            animacion: false,
            decoracion: false,
        },
        validationSchema: yup.object({
            name: yup.string().required(),
            email: yup
                .string()
                .email()
                .required(),
            phone: yup.string().required(),
            comments: yup.string(),
            manteleria: yup.boolean(),
            sillas: yup.boolean(),
            animacion: yup.boolean(),
            decoracion: yup.boolean(),
        }),
        onSubmit: values => {
            values.additionals = [];
            let additionals = ['manteleria', 'sillas', 'animacion', 'decoracion'];
            for(let i in additionals) {
                if(values[additionals[i]]) {
                    values.additionals.push(additionals[i]);
                }
            }
            onClick(values);
        },
    });

    const classNames = {};
    for (var key in formik.values) {
        classNames[key] = ["form-control"];
        if (submiting) {
            classNames[key].push("disabled");
        }
        if (formik.touched[key]) {
            if (formik.errors[key]) {
                classNames[key].push("is-invalid");
            } else {
                classNames[key].push("is-valid");
            }
        }
    }

    return (
        <Step
            title="Todo en order! A dónde enviamos la cotización?"
            description="Ingrese su información de contacto y cualquier detalle adicional"
            btn_label="Obtener Cotización Ahora"
            btn_description=''
            {...props}
            onClick={formik.handleSubmit}
            submiting={submiting}
        >
            <div className="step-content contact">
                <div className="row text-left">
                    <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="input_name">Nombre Completo:</label>
                            <input
                                id="input_name"
                                name="name"
                                className={classNames.name.join(" ")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                disabled={submiting}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input_email">
                                Correo Electrónico:
                            </label>
                            <input
                                type="email"
                                id="input_email"
                                name="email"
                                className={classNames.email.join(" ")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                disabled={submiting}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="input_phone">Teléfono:</label>
                            <input
                                id="input_phone"
                                name="phone"
                                className={classNames.phone.join(" ")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                disabled={submiting}
                            />
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="form-group">
                            <label htmlFor="text_comments">
                                Información Adicional
                            </label>
                            <textarea
                                id="text_comments"
                                name="comments"
                                rows="5"
                                className={classNames.comments.join(" ")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.comments}
                                disabled={submiting}
                            ></textarea>
                        </div>
                        <div className="form-group form-checks">
                            <label htmlFor="additional_services">
                                Servicios Adicionales
                            </label>
                            <div id="additional_services" className="row">
                                <div className="col">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="check_manteleria"
                                            name="manteleria"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.manteleria}
                                            disabled={submiting}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="check_manteleria"
                                        >
                                            Mantelería
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="check_animacion"
                                            name="animacion"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.animacion}
                                            disabled={submiting}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="check_animacion"
                                        >
                                            Animación
                                        </label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="check_sillas"
                                            name="sillas"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.sillas}
                                            disabled={submiting}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="check_sillas"
                                        >
                                            Sillas
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="check_decoracion"
                                            name="decoracion"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.decoracion}
                                            disabled={submiting}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="check_decoracion"
                                        >
                                            Decoración
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Step>
    );
};

export default Contact;
