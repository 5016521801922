import React from "react";
import PropTypes from "prop-types";

import Button from "../common/button";

const Step = ({
    children,
    title,
    description,
    className,
    btn_description,
    btn_label,
    index,
    onClick,
    submiting,
}) => {
    return (
        <div
            className={`step step-${index} text-center d-flex flex-column justify-content-between ${className}`}
        >
            <div className="title">
                <h2>{title}</h2>
                <h4>{description}</h4>
            </div>
            {children}
            <Button
                label={btn_label}
                description={btn_description}
                onClick={onClick}
                submiting={submiting}
            />
        </div>
    );
};

Step.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    className: PropTypes.string,
    btn_description: PropTypes.string,
    btn_label: PropTypes.string,
};

Step.defaultProps = {
    btn_label: "Siguiente",
    btn_description: "Facil, Rápido y Seguro",
};

export default Step;
