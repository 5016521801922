import React, { useEffect } from "react";

import TextRotator from "../common/text-rotator.js";
import loadingImage from "../../images/loader.svg";

const LoadingStep = ({ className, onClick, onScreen }) => {
    useEffect(() => {
        if (onScreen) setTimeout(() => onClick(), 3000);
    }, [onScreen]);

    return (
        <div
            className={`step loading text-center d-flex flex-column justify-content-center align-items-center ${className}`}
        >
            <h2>
                Casi listos, espera miestras <br />
                validamos la información
            </h2>
            <img src={loadingImage} width={204} alt="Loading..." />
            {onScreen && (
                <TextRotator
                    labels={[
                        "Confirmando cobertura...",
                        "Confirmando disponibilidad...",
                    ]}
                    time={3000}
                />
            )}
        </div>
    );
};

export default LoadingStep;
