import React, { useState } from "react";

import Option from "./option";

const Options = ({ values, multiple, onSelect }) => {
    const defaultOptions = values.map(value => {
        return {
            value: value,
            active: false,
        };
    });

    const [options, setActiveOptions] = useState(defaultOptions);

    const handleClick = index => {
        const new_options = options.map((option, i) => {
            if (!multiple) {
                option.active = false;
            }
            if (i === index) {
                option.active = !option.active;
            }
            return option;
        });

        const selected = new_options
            .filter(option => option.active)
            .map(option => option.value);
        onSelect(selected);

        setActiveOptions(new_options);
    };

    const optionsEl = options.map((option, index) => (
        <Option
            key={index}
            index={index}
            value={option.value}
            active={option.active}
            onClick={handleClick}
        />
    ));
    return <div className="options d-flex">{optionsEl}</div>;
};

Options.defaultProps = {
    multiple: false,
};

export default Options;
