import React, { useState } from "react";
import Slider from "rc-slider";

import Step from "./step";

import "rc-slider/assets/index.css";

import * as yup from "yup";

const SliderWithTootip = Slider.createSliderWithTooltip(Slider);

const Assistants = ({ onClick, ...props }) => {
    const [assistants, setAssistants] = useState(50);

    const validate = () => {
        let schema = yup
            .number()
            .min(25)
            .max(300)
            .required();
        schema.validate(assistants).then(success => {
            if (!!success)
                onClick({
                    assistants,
                });
        });
    };

    const handleChange = value => {
        setAssistants(value);
    };

    return (
        <Step
            title="Cuántos invitados esperas para el evento?"
            description="Atendemos desde pequeñas reuniones hasta grandes convenciones empresariales"
            {...props}
            onClick={validate}
        >
            <div className="step-content">
                <SliderWithTootip
                    min={25}
                    max={300}
                    defaultValue={50}
                    step={5}
                    tipProps={{
                        defaultVisible: true,
                        visible: true,
                    }}
                    tipFormatter={value =>
                        value > 295 ? `${value}+` : `${value}`
                    }
                    onChange={handleChange}
                />
                <div className="row mt-3">
                    <div className="col text-left">
                        <p>25</p>
                    </div>
                    <div className="col text-right">
                        <p>300+</p>
                    </div>
                </div>
            </div>
        </Step>
    );
};

export default Assistants;
