import React from "react";

const Option = ({ value, index, active, onClick }) => {
    const handleClick = e => {
        onClick(index);
    };

    return (
        <div
            className={`option ` + (active ? "selected" : "")}
            onClick={handleClick}
            role="button"
        >
            <p>{value}</p>
        </div>
    );
};

export default Option;
